import { Controller } from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";

export default class extends Controller {
    static targets = [ "tabGroup" ]

    async connect() {
        // wait for components loading
        await Promise.allSettled([
            customElements.whenDefined('sl-tab-group'),
        ]);

        const tabGroup = this.tabGroupTarget;
        tabGroup.addEventListener("sl-tab-show", this.handleTabShow.bind(this));
        // the target tab to select
        const target = this.data.get("active");
        // show is the component method to select a tab
        tabGroup.show(target);
    }

    switch_to_user_items(event) {
        const tabGroup = this.tabGroupTarget;
        tabGroup.show("user_items");
    }

    handleTabShow(event) {
        // change the active tab data attribute
        const tab = event.detail.name;
        this.data.set("back_active_tab", tab);
        // Call the turbo action to update the back button according to the active tab
        this.turbo_back_button();
    }

    turbo_back_button() {
        const dataset = this.element.dataset;
        const keys = Object.keys(dataset);

        let url = "";
        keys.filter(key => key.startsWith("evsession-TabactiveBack_")).forEach(key => {
            const newKey = key.replace(/^evsession-TabactiveBack_/, "");
            url += "/"+newKey+"/"+dataset[key];
        });

        const request = new FetchRequest('get', url,{responseKind: "turbo-stream"});
        request.perform().then((response) => {
            //console.log("success");
        });
    }
}