import DriverFactory from "@/js/lib/DriverFactory.js";
import help_controller from "@/js/controllers/shared/help_controller";

export default class extends help_controller {

    connect() {
        //if (this.element.dataset['strategy'] === "showMy")
        //    this.autoTriggerMap.set(true, 'showMy');
        // setting the auto trigger map for help on specific events
        if (this.element.dataset['eventsCount']) {
            this.autoTriggerMap.set(this.element.dataset['eventsCount'] === '0', 'indexDialog');
        }
        if (this.element.dataset['firsteventcreation']) {
            this.autoTriggerMap.set(this.element.dataset['firsteventcreation'] === "true", 'showMy');
        }
        if (this.element.dataset['firsteventjoin']) {
            this.autoTriggerMap.set(this.element.dataset['firsteventjoin'] === "true", 'showOtherWait');
        }
        // always set the autoTriggerMap before calling super.connect()
        super.connect();
    }

    showMy() {
        const showMyScope = 'frontend.help.event.index.showMy';
        const i18n = this.application.i18n;

        const eventName = this.element.querySelector(".event_list").getElementsByTagName("H2")[0].textContent

        let conf = DriverFactory.defaultConfig(i18n);
        conf.doneBtnText = i18n.t('doneBtnText', {scope: showMyScope});
        conf.steps = [
            { element: this.element.querySelector(".event_list"),
                popover: {
                    title: i18n.t('steps.0.title', {scope: showMyScope}),
                    description: i18n.t('steps.0.content', {scope: showMyScope, eventName: eventName})}},
            { element: this.element.querySelector(".avatar-group") ,
                popover: {
                    title: i18n.t('steps.1.title', {scope: showMyScope}),
                    description: i18n.t('steps.1.content', {scope: showMyScope}) } },
            { element: this.element.querySelector(".invite-button"),
                popover: {
                    title: i18n.t('steps.2.title', {scope: showMyScope}),
                    description: i18n.t('steps.2.content', {scope: showMyScope})} },
            { element: this.element.querySelector(".manage-button"),
                popover: {
                    title: i18n.t('steps.3.title', {scope: showMyScope}),
                    description: i18n.t('steps.3.content', {scope: showMyScope}),
                    onNextClick: () => {
                        // simulate click on the button
                        this.element.querySelector(".manage-button").children[0].click();
                        this.driver.moveNext();
                    }} },
        ]
        return conf;
    }

    showOther() {
        const showOtherScope = 'frontend.help.event.index.showOther';
        const i18n = this.application.i18n;

        const eventName = this.element.querySelector(".event_list").getElementsByTagName("H2")[0].textContent

        let conf = DriverFactory.defaultConfig(i18n);
        conf.doneBtnText = i18n.t('doneBtnText', {scope: showOtherScope});
        conf.steps = [
                { element: this.element.querySelector(".event_list"),
                    popover: {
                        title: i18n.t('steps.0.title', {scope: showOtherScope}),
                        description: i18n.t('steps.0.content', {scope: showOtherScope, eventName: eventName})} },
                { element: this.element.querySelector(".avatar-group") ,
                    popover: {
                        title: i18n.t('steps.1.title', {scope: showOtherScope}),
                        description: i18n.t('steps.1.content', {scope: showOtherScope})} },
               // { element: this.element.querySelector(".deny-button"), popover: { title: 'Annuler votre invitation.', description: 'Si vous souhaitez annuler et quitter cet événement, vous pouvez cliquer sur ce bouton.'} },
                { element: this.element.querySelector(".show-event-button"),
                    popover: {
                        title: i18n.t('steps.2.title', {scope: showOtherScope}),
                        description: i18n.t('steps.2.content', {scope: showOtherScope}),
                        onNextClick: () => {
                            // simulate click on the button
                            this.element.querySelector(".show-event-button").children[0].click();
                            this.driver.moveNext();
                        }} },
            ];

        return conf;
    }

    showOtherWait() {
        const showOtherScope = 'frontend.help.event.index.showOther';
        const i18n = this.application.i18n;

        const eventName = this.element.querySelector(".event_list").getElementsByTagName("H2")[0].textContent

        let conf = DriverFactory.defaultConfig(i18n);
        conf.doneBtnText = i18n.t('wait.doneBtnText', {scope: showOtherScope});
        conf.steps = [
            { element: this.element.querySelector(".event_list"),
                popover: {
                    title: i18n.t('steps.0.title', {scope: showOtherScope}),
                    description: i18n.t('steps.0.content', {scope: showOtherScope, eventName: eventName})} },
            { element: this.element.querySelector(".avatar-group") ,
                popover: {
                    title: i18n.t('steps.1.title', {scope: showOtherScope}),
                    description: i18n.t('steps.1.content', {scope: showOtherScope})} },
            { element: this.element.querySelector(".accept-event-button"),
                popover: {
                    title: i18n.t('wait.steps.0.title', {scope: showOtherScope}),
                    description: i18n.t('wait.steps.0.content', {scope: showOtherScope}),
                    onNextClick: () => {
                        // simulate click on the button
                        this.element.querySelector(".accept-event-button").children[0].click();
                        this.driver.moveNext();
                    }} },
        ];

        return conf;
    }

    createEvent() {
        const createEventScope = 'frontend.help.event.index.createEvent';
        const i18n = this.application.i18n;

        const dialog = document.querySelector('sl-dialog');

        let conf = DriverFactory.defaultConfig(i18n);
        conf.steps = [
            { popover: {
                title: i18n.t('steps.0.title', {scope: createEventScope}),
                description: i18n.t('steps.0.content', {scope: createEventScope})},
            },
            { element: '.new_event',
                popover: {
                    title: i18n.t('steps.1.title', {scope: createEventScope}),
                    description: i18n.t('steps.1.content', {scope: createEventScope}),
                    onNextClick: () => {
                        dialog.show();
                        this.driver.moveNext();
                    },
                    onPrevClick: () =>{
                        dialog.hide();
                        this.driver.movePrevious();
                    }} },
            { element:'#event_form' ,
                popover: {
                    title: i18n.t('steps.2.title', {scope: createEventScope}),
                    description: i18n.t('steps.2.content', {scope: createEventScope}) } },
            { element: '#event_image',
                popover: {
                    title: i18n.t('steps.3.title', {scope: createEventScope}),
                    description: i18n.t('steps.3.content', {scope: createEventScope}) } },
            { element: '#create_event_button',
                popover: {
                    title: i18n.t('steps.4.title', {scope: createEventScope}),
                    description: i18n.t('steps.4.content', {scope: createEventScope}) } },
            ];

        return conf;
    }

    indexDialog() {
        const indexDialogScope = 'frontend.help.event.index.indexDialog';
        const i18n = this.application.i18n;

        const dialog = document.querySelector('sl-dialog');
        let conf = DriverFactory.defaultConfig(i18n);
        conf.steps = [
            { popover: {
                title: i18n.t('steps.0.title', {scope: indexDialogScope}),
                description: i18n.t('steps.0.content.0', {scope: indexDialogScope}) +
                        "<sl-button data-controller='event--help--eventIndex' data-action='click->event--help--eventIndex#show' data-strategy='createEvent' >" +
                        i18n.t('steps.0.content.1', {scope: indexDialogScope}) + "</sl-button>" +
                        "<sl-button data-controller='event--help--eventIndex' data-action='click->event--help--eventIndex#show' data-strategy='joinEvent'>" +
                        i18n.t('steps.0.content.2', {scope: indexDialogScope}) + "</sl-button>"}},
        ];
        conf.showButtons = ['close'];

        return conf;
    }

    joinEvent() {
        const joinEventScope = 'frontend.help.event.index.joinEvent';
        const i18n = this.application.i18n;

        let conf = DriverFactory.defaultConfig(i18n);
        conf.steps = [
            { popover: {
                title: i18n.t('steps.0.title', {scope: joinEventScope}),
                description: i18n.t('steps.0.content', {scope: joinEventScope}) } },
            { popover: {
                title: i18n.t('steps.1.title', {scope: joinEventScope}),
                description: i18n.t('steps.1.content', {scope: joinEventScope}) } },
            //TODO add a field to enter the event code
            //TODO add a button to scan the QR code
        ];

        return conf;
    }

}