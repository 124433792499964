import {Controller} from "@hotwired/stimulus"

export default class extends Controller {
    static targets = ["invite"]

    connect() {
        let event = this.element.dataset.invite
        console.log("This is a go link");

        localforage.setDriver([
            localforage.INDEXEDDB,
            localforage.WEBSQL,
            localforage.LOCALSTORAGE
        ]).then(function () {
            var key = 'invites';
            if (event) {
                localforage.setItem(key, [event]).then(function (value) {
                    console.log('set value[0]');
                    console.log(value[0]);
                }).catch(function (err) {
                    console.log(err);
                });
            }
        });
    }
}