import {Controller} from "@hotwired/stimulus";

export default class extends Controller {
    static targets = ["parentDropZone"]

    connect() {
        super.connect();
    }

    disconnect() {
        super.disconnect();
        this.hideParentDropZone();
    }

    dragstart(event) {
        this.dragedElement = event.target.closest("li");
        this.parentDropUrl = this.dragedElement.dataset.parentDropUrl;
        let elems = this.parentDropZoneTargets;
        elems.forEach((elem) => {
            elem.style.display = "block";
        });
    }

    dragend(event){
        this.hideParentDropZone();
    }

    hideParentDropZone() {
        let elems = this.parentDropZoneTargets;
        elems.forEach((elem) => {
            elem.style.display = "none";
        });
    }

    dragOverParentDropZone(event){
        event.preventDefault();
    }

    dropToParentSibling(event) {
        fetch(this.parentDropUrl).then (response => response.text())
            .then(html => Turbo.renderStreamMessage(html));
    }

    dragOverArticle(event) {
        let target = event.target.closest("li");
        if (target === this.dragedElement) {
            return;
        }
        else {
            event.preventDefault();
        }
    }

    dropToArticle(event) {
        let target = event.target.closest("li");
        if (target === this.dragedElement) {
            return;
        }
        let dropUrl = this.dragedElement.dataset.articleDropUrl + target.dataset.articleId;
        fetch(dropUrl).then (response => response.text())
            .then(html => Turbo.renderStreamMessage(html));
    }
}