import { driver } from "driver.js";
import "driver.js/dist/driver.css";

export default class DriverFactory{

    static defaultDriver(i18n, steps = []) {
        const config = DriverFactory.defaultConfig(i18n);
        config.steps = steps;
        return driver(config);
    }

    static defaultConfig(i18n) {
        const scope = 'frontend.help.button';
        return {
            showProgress: true,
            nextBtnText: i18n.t('nextBtnText', {scope: scope}),
            prevBtnText: i18n.t('prevBtnText', {scope: scope}),
            doneBtnText: i18n.t('doneBtnText', {scope: scope}),
            showButtons: ['next', 'previous', 'close'],
            steps: []
        };
    }

}