import DriverFactory from "@/js/lib/DriverFactory.js";
import Help_controller from "@/js/controllers/shared/help_controller";


export default class extends Help_controller {
    static targets = ["element"]

    participantJoinStrategies = ['not_participate', 'participant_no_order']

    connect() {
        // setting the auto trigger map for help on specific events

        // trigger no_name when create a session with no name
        if (this.element.dataset['strategy'] === 'owner_no_name') {
            this.autoTriggerMap.set(this.element.dataset['new'], 'owner_no_name');
        }

        if (this.participantJoinStrategies.includes(this.element.dataset['strategy']) ) {
            this.autoTriggerMap.set(true, 'participant_first_session');
        }

        this.autoTriggerMap.set(this.element.dataset['blankNameChange'], 'owner_no_menu');

        this.autoTriggerMap.set(this.element.dataset['eventsCount'] === '0', 'indexDialog');

        // always set the autoTriggerMap before calling super.connect()
        super.connect();
    }

    owner_no_name() {
        const i18n = this.application.i18n;
        const scope = 'frontend.help.evsession.index.owner.noName';
        let conf = DriverFactory.defaultConfig(i18n);

        const evsessionNameElement = this.element.getElementsByTagName("H3")[0];
        const editButton = this.element.getElementsByClassName("edit-button")[0];

        conf.steps = [
            { element: evsessionNameElement , popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope}) }},
            { element: editButton , popover: { title: i18n.t("steps.1.title", {scope: scope}), description: i18n.t("steps.1.content", {scope: scope}),
                    nextBtnText: i18n.t("steps.1.nextBtnText", {scope: scope}),
                    onNextClick: () => {
                        editButton.click();
                        this.driver.moveNext();
                    } }},
            { element: this.element , popover: { title: i18n.t("steps.2.title", {scope: scope}), description: i18n.t("steps.2.content", {scope: scope}) }},
            { element: this.element , popover: { title: i18n.t("steps.3.title", {scope: scope}), description: i18n.t("steps.3.content", {scope: scope}) }},
        ];
        return conf;
    }

    owner_no_menu(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.evsession.index.owner.noMenu';
        let conf = DriverFactory.defaultConfig(i18n);

        const articlesButton = this.element.getElementsByClassName("articles")[0].closest("sl-button");

        conf.doneBtnText = i18n.t("doneBtnText", {scope: scope});
        conf.steps = [
            { element: this.element, popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope}) }},
            { element: articlesButton, popover: { title: i18n.t("steps.1.title", {scope: scope}), description: i18n.t("steps.1.content", {scope: scope}) ,
                    onNextClick: () => {
                        articlesButton.click();
                        this.driver.moveNext();
                    } }},
        ]
        return conf;
    }

    owner_no_participants(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.evsession.index.owner.no_participants';
        const sharedScope = 'frontend.help.evsession.index.shared';
        let conf = DriverFactory.defaultConfig(i18n);

        const evsession = this.element.getElementsByClassName("evsession")[0];
        const evsessionName = evsession.getElementsByTagName("H3")[0].textContent;
        const avatarGroup = evsession.getElementsByClassName("avatar-group")[0];
        const inviteButton = evsession.parentElement.getElementsByClassName("invite-button")[0]

        conf.doneBtnText = i18n.t("doneBtnText", {scope: scope});
        conf.steps = [
            { element: evsession, popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope}) }},
            { element: avatarGroup, popover: { title: i18n.t("participantsList.title", {scope: sharedScope}), description: i18n.t("participantsList.content", {scope: sharedScope, barbecueName: evsessionName, href: avatarGroup.getElementsByTagName("a")[0].href})}},
            { element: inviteButton, popover: { title: i18n.t("steps.1.title", {scope: scope}), description: i18n.t("steps.1.content", {scope: scope}),
                    onNextClick: () => {
                        inviteButton.click();
                    }}},

        ]
        return conf;
    }

    owner_wait_for_orders(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.evsession.index.owner.wait_for_orders';
        const sharedScope = 'frontend.help.evsession.index.shared';
        let conf = DriverFactory.defaultConfig(i18n);

        const evsession = this.element.getElementsByClassName("evsession")[0];
        const evsessionName = evsession.getElementsByTagName("H3")[0].textContent;
        const orderButton = evsession.getElementsByClassName("order-owner-button")[0];

        conf.steps = [
            { element: evsession, popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope, barbecueName: evsessionName}) }},
            { element: orderButton, popover: { title: i18n.t("orderPage.title", {scope: sharedScope}), description: i18n.t("orderPage.content", {scope: sharedScope}),
                    nextBtnText: i18n.t("orderPage.nextBtnText", {scope: sharedScope}),
                    onNextClick: () => {
                        orderButton.click();
                    }}},
        ]
        return conf;
    }

    owner_need_to_lock_orders(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.evsession.index.owner.need_to_lock_orders';
        const sharedScope = 'frontend.help.evsession.index.shared';
        let conf = DriverFactory.defaultConfig(i18n);

        const evsession = this.element.getElementsByClassName("evsession")[0];
        const evsessionName = evsession.getElementsByTagName("H3")[0].textContent;
        const orderButton = evsession.getElementsByClassName("order-owner-button")[0];

        conf.steps = [
            { element: evsession, popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope, barbecueName: evsessionName}) }},
            { element: orderButton, popover: { title: i18n.t("orderPage.title", {scope: sharedScope}), description: i18n.t("orderPage.content", {scope: sharedScope}),
                    nextBtnText: i18n.t("orderPage.nextBtnText", {scope: sharedScope}),
                    onNextClick: () => {
                        orderButton.click();
                    }}},
        ]
        return conf;
    }

    owner_locked_unfinished_order(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.evsession.index.owner.locked_unfinished_order';
        let conf = DriverFactory.defaultConfig(i18n);

        const evsession = this.element.getElementsByClassName("evsession")[0];
        const evsessionName = evsession.getElementsByTagName("H3")[0].textContent;
        const orderButton = evsession.getElementsByClassName("order-owner-button")[0];

        conf.doneBtnText = i18n.t("doneBtnText", {scope: scope});
        conf.steps = [
            { element: evsession, popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope, barbecueName: evsessionName}),
                    onNextClick: () => {
                        orderButton.click();
                    }}}
        ];
        return conf;
    }

    owner_cooking_in_progress(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.evsession.index.owner.cooking_in_progress';
        let conf = DriverFactory.defaultConfig(i18n);

        const evsession = this.element.getElementsByClassName("evsession")[0];
        const evsessionName = evsession.getElementsByTagName("H3")[0].textContent;
        const orderButton = evsession.getElementsByClassName("order-owner-button")[0];

        conf.doneBtnText = i18n.t("doneBtnText", {scope: scope});
        conf.steps = [
            { element: evsession, popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope, barbecueName: evsessionName}),
                    onNextClick: () => {
                        orderButton.click();
                    }}}
        ];
        return conf;
    }

    not_participate(){
        const i18n = this.application.i18n;
        const sharedScope = 'frontend.help.evsession.index.shared';
        let conf = DriverFactory.defaultConfig(i18n);

        const eventHelpButton =  document.getElementsByClassName("event-show-help-button")[0];

        conf.onPopoverRender = () => {
            eventHelpButton.click();
        }

        conf.steps = [
            { element: this.element, popover: { title: i18n.t("noHelp.steps.0.title", {scope: sharedScope}), description: i18n.t("noHelp.steps.0.content", {scope: sharedScope}) }},
        ]
        return conf;
    }

    participant_no_order(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.evsession.index.participant.order.no';
        const sharedScope = 'frontend.help.evsession.index.shared';
        let conf = DriverFactory.defaultConfig(i18n);

        const orderButton = this.element.getElementsByClassName("order-button")[0];
        const evsessionName = this.element.getElementsByTagName("H3")[0].textContent;


        conf.steps = [
            { element: this.element, popover: { title: i18n.t("participantSessionSynthesis.title", {scope: sharedScope}), description: i18n.t("participantSessionSynthesis.content", {scope: sharedScope, barbecueName: evsessionName}) }},
            { element: orderButton, popover: { title: i18n.t("orderButton.title", {scope: sharedScope}), description: i18n.t("orderButton.content", {scope: sharedScope, barbecueName: evsessionName}),
                nextBtnText: i18n.t("orderButton.nextBtnText", {scope: sharedScope}),
                onNextClick: () => {
                    orderButton.click();
                    this.driver.moveNext();
                } }},
        ];
        return conf;
    }

    participant_first_session(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.evsession.index.participant.firstSession';
        const sharedScope = 'frontend.help.evsession.index.shared';
        let conf = DriverFactory.defaultConfig(i18n);

        // no need to check if the event have sessions because this controller is called by session index
        const evsession = document.getElementById("evsessions").getElementsByClassName("evsession")[0];

        //const orderButton = evsession.getElementsByClassName("order-button")[0];
        const evsessionName = evsession.getElementsByTagName("H3")[0].textContent;
        const avatarGroup = evsession.getElementsByClassName("avatar-group")[0];

        conf.doneBtnText = i18n.t("doneBtnText", {scope: scope});
        conf.steps = [
            { element: evsession, popover: { title: i18n.t("participantSessionSynthesis.title", {scope: sharedScope}), description: i18n.t("participantSessionSynthesis.content", {scope: sharedScope, barbecueName: evsessionName}) }},
            { element: avatarGroup, popover: { title: i18n.t("participantsList.title", {scope: sharedScope}), description: i18n.t("participantsList.content", {scope: sharedScope, barbecueName: evsessionName, href: avatarGroup.getElementsByTagName("a")[0].href}),
                    onNextClick: () => {
                        evsession.parentElement.getElementsByClassName("evsession-index-help")[0].click();
                        this.driver.moveNext();
                    } }},
            /*{ element: evsession, popover: { title: i18n.t("orderButton.title", {scope: sharedScope}), description: i18n.t("orderButton.content", {scope: sharedScope, barbecueName: evsessionName}),
                    nextBtnText: i18n.t("orderButton.nextBtnText", {scope: sharedScope}),
                    onNextClick: () => {
                        orderButton.click();
                        this.driver.moveNext();
                    } }},*/
        ];
        return conf;
    }

    participant_no_menu(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.evsession.index.participant.noMenu';
        let conf = DriverFactory.defaultConfig(i18n);

        const evsessionName = this.element.getElementsByTagName("H3")[0].textContent;

        conf.steps = [
            { element: this.element, popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope, barbecueName: evsessionName}) }},
        ]
        return conf;
    }

    participant_event_pause(){
        const i18n = this.application.i18n;
        const sharedScope = 'frontend.help.evsession.index.shared';
        let conf = DriverFactory.defaultConfig(i18n);

        const eventHelpButton =  document.getElementsByClassName("event-show-help-button")[0];

        conf.onPopoverRender = () => {
            eventHelpButton.click();
        }

        conf.steps = [
            { element: this.element, popover: { title: i18n.t("noHelp.steps.0.title", {scope: sharedScope}), description: i18n.t("noHelp.steps.0.content", {scope: sharedScope}) }},
        ]
        return conf;
    }

    participant_not_confirmed_order(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.evsession.index.participant.order.notConfirmed';
        let conf = DriverFactory.defaultConfig(i18n);

        const orderButton = this.element.getElementsByClassName("order-button")[0];
        const evsessionName = this.element.getElementsByTagName("H3")[0].textContent;

        conf.steps = [
            { element: this.element, popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope, barbecueName: evsessionName}),
                nextBtnText: i18n.t("steps.0.nextBtnText", {scope: scope}),
                onNextClick: () => {
                    orderButton.click();
            } }},
        ]
        return conf;
    }

    participant_orders_not_locked(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.evsession.index.participant.order.notLocked';
        let conf = DriverFactory.defaultConfig(i18n);

        const evsessionName = this.element.getElementsByTagName("H3")[0].textContent;

        conf.steps = [
            { element: this.element, popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope, barbecueName: evsessionName}) }},
        ]
        return conf;
    }

    participant_orders_locked(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.evsession.index.participant.order.locked';
        let conf = DriverFactory.defaultConfig(i18n);

        const evsessionName = this.element.getElementsByTagName("H3")[0].textContent;

        conf.steps = [
            { element: this.element, popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope, barbecueName: evsessionName}) }},
        ]
        return conf;
    }

    participant_no_order_but_locked(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.evsession.index.participant.order.noButLocked';
        let conf = DriverFactory.defaultConfig(i18n);

        const evsessionName = this.element.getElementsByTagName("H3")[0].textContent;
        conf.steps = [
            { element: this.element, popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope, barbecueName: evsessionName}) }},
        ]
        return conf;
    }

}