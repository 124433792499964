import { Controller } from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";
import consumer from './consumer';

export default class extends Controller {
    static targets = ['events'];

    connect() {
        this.channel = consumer.subscriptions.create({ channel: "ParticipantChannel", session: this.element.id }, {
            connected: this._cableConnected.bind(this),
            disconnected: this._cableDisconnected.bind(this),
            received: this._cableReceived.bind(this),
        });
    }

    _cableConnected() {
        // Called when the subscription is ready for use on the server
    }

    _cableDisconnected() {
        // Called when the subscription has been terminated by the server
    }

    _cableReceived(data) {
        var currentUrl = window.location.href;
        var urlParts = currentUrl.split("/");
        var lastPart = urlParts[urlParts.length - 1];
        if (lastPart === "participant") {
            let url = "/events/"+ data["events"];
            window.location.href = url;
        }

        else if (urlParts.length == 5 && urlParts[urlParts.length - 2] == "events")  {
            let url = "";

            for (const key in data) {
                url += "/"+key+"/"+data[key];
            }
            url += "/change_status";
            console.log(url);
            const request = new FetchRequest('get', url, { responseKind: "turbo-stream" });
            request.perform().then((response) => {
                console.log("success cable");
            });
        }
    }
}