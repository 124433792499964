import {Controller} from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";

export default class extends Controller {
    connect() {
        localforage.getItem('invites').then((values) => {
            for (const event of values) {
                window.instance = values;
                if (!this.checkEvent(event, values)) {
                    let url = 'events/' + event + '/get.json'
                    const request = new FetchRequest('get', url, {responseKind: "turbo-stream"});
                    request.perform().then((response) => {
                        //console.log("success");
                    });
                };
            }
        }).catch(function (err) {
            console.log(err);
        });
    }

    checkEvent(event, values) {
        let ev = document.getElementById("event_" + event)
        if (event) {
            //console.log("success check event");
            let url = 'events/' + event + '/check_invite.json'
            const request = new FetchRequest('get', url, {responseKind: "json"});
            request.perform().then(response => response.json)
                .then((json) => {
                    //console.log('json');
                    //console.log(json.check);
                    if (json.check === 'true') {
                        if (values.length === 1) {
                            thiS.removeKey();
                        } else {
                            this.removeItem(event, values);
                        }
                        return true;
                    } else {
                        return false;
                    }
                });
        } else {
            console.log("NO check event");
            return false;
        };
    }

    removeKey() {
        localforage.removeItem('invites').then(function () {
            console.log('Key is cleared!');
        }).catch(function (err) {
            console.log(err);
        });
    }

    removeItem(event, values) {
        var filteredValues = values.filter(function (e) {
            return e !== event
        })
        localforage.setItem('invites', filteredValues).then(function (value) {
            // Do other things once the value has been saved.
            console.log(value);
        }).catch(function (err) {
            // This code runs if there were any errors
            console.log(err);
        });
    }
}