import Sortable from '@stimulus-components/sortable'

export default class extends Sortable {

    // Override the defaultOptions getter to provide custom options
    get defaultOptions() {
        return {
            animation: 200,
            handle: this.element.dataset.sortableHandleValue,
        }
    }
}