import { Controller } from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";
import consumer from './consumer';

export default class extends Controller {
    static targets = ['events', 'evsessions', 'select_user', 'select_participant', 'user', 'select', 'new'];

    connect() {
        this.channel = consumer.subscriptions.create('ConnectChannel', {
            connected: this._cableConnected.bind(this),
            disconnected: this._cableDisconnected.bind(this),
            received: this._cableReceived.bind(this),
        });
    }

    clearInput() {
        this.inputTarget.value = '';
    }

    _cableConnected() {
        // Called when the subscription is ready for use on the server
    }

    _cableDisconnected() {
        // Called when the subscription has been terminated by the server
    }

    _cableReceived(data) {
        const variablesToAvoid = ["select", "new"];
        let url = "";

        for (const key in data) {
            if (!variablesToAvoid.includes(key)) {
                url += `/${key}/${data[key]}`;
            }
        }
        url += `?select=${data.select}`;
        url += `&new=${data.new}`

        const request = new FetchRequest('get', url, { responseKind: "turbo-stream" });
        request.perform().then((response) => {
            console.log("success");
        });
    }
}