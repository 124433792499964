import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "element" ]

    connect() {
        this.element.addEventListener(
            "sl-change",
            this.submit.bind(this)
        );
    }

    submit() {
        this.element.closest("form").requestSubmit();
    }
}