import { Application } from '@hotwired/stimulus'
import { registerControllers } from 'stimulus-vite-helpers'
import {Turbo} from "@hotwired/turbo-rails";
import Clipboard from 'stimulus-clipboard'
//import Sortable from '@stimulus-components/sortable'
import Autocomplete from 'stimulus-autocomplete'
import I18nLoader from "@/js/lib/I18nLoader";

const application = Application.start()

application.i18n = I18nLoader.loadI18n();

const controllers = import.meta.glob('./**/*_controller.js',{ eager: true })
const shoe_controllers = import.meta.glob('../shoelaceControllers/*.js',{ eager: true })
const channel_controllers = import.meta.glob('../channels/*_controller.js',{ eager: true })

application.register('autocomplete', Autocomplete)

registerControllers(application, controllers)
registerControllers(application, shoe_controllers)
registerControllers(application, channel_controllers)
application.register('clipboard', Clipboard)

Turbo.StreamActions.update_data_strategy = function () {
    this.targetElements.forEach((target) => {
        target.setAttribute("data-strategy", this.templateContent.textContent);
    });
};
Turbo.StreamActions.update_data_controller = function () {
    this.targetElements.forEach((target) => {
        target.setAttribute("data-controller", this.templateContent.textContent);
    });
};

/*if (navigator.serviceWorker) {
    navigator.serviceWorker.register("/service-worker.js", { scope: "/" })
        .then(() => navigator.serviceWorker.ready)
        .then((registration) => {
            if ("SyncManager" in window) {
                registration.sync.register("sync-forms");
            } else {
                console.log("This browser does not support background sync.")
            }
        }).then(() => console.log("[Companion]", "Service worker registered!"));
}*/

document.addEventListener("click", event => {
    const element = event.target.closest("sl-button[data-confirm]")
    if (element && !confirm(element.dataset.confirm)) {
        event.preventDefault();
    }
});
