import Clipboard from 'stimulus-clipboard'

export default class extends Clipboard {
    connect() {
        super.connect()
        // Firefox does not support copying images to clipboard.
        if(navigator.userAgent.toLowerCase().indexOf('firefox') > -1){
            let bt = document.querySelector('.copy_qr')
                bt.style = 'display:none';
        }
    }

    // Function to override on copy.
    copy() {
        //target shoelace shadowroot for qrcode
        let shadowroot = document.querySelector('sl-qr-code').shadowRoot;
        let canvas = shadowroot.querySelector('canvas');
        canvas.toBlob(blob => navigator.clipboard.write([new ClipboardItem({'image/png': blob})]))
    }

}