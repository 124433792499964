import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
    static targets = [ "sortable", "dragNDrop", "dragArea", "sortableSwitch" ]

    addSortableController() {
        if (event.target.hasAttribute("checked")){
            let elems = this.sortableTargets;
            elems.forEach((elem) => {
                elem.setAttribute("data-controller", "article--sortable");
            });
        } else {
            let elems = this.sortableTargets;
            elems.forEach((elem) => {
                if (elem.hasAttribute("data-controller")){
                    elem.removeAttribute("data-controller");
                }
            });
            /**
            setTimeout((self=this) => {
                let elems = self.dragNDropTargets;
                elems.forEach((elem) => {
                    elem.setAttribute("draggable", "true");
                });
            }, "1000");
*/
        }

    }

    addSortableSwitch(){
        let elems = this.sortableSwitchTargets;
        elems.forEach((elem) => {
            elem.style.display = elem.style.display === "none" ? "block" : "none";
        });
    }

    addDragNDrop() {
        let elems = this.dragNDropTargets;
        elems.forEach((elem) => {
            if (elem.getAttribute("draggable") === "true"){
                elem.setAttribute("draggable", "false");
            }
            else {
                elem.setAttribute("draggable", "true");
            }
        });
        let dragArea = this.dragAreaTargets;
        dragArea.forEach((elem) => {
            if (elem.hasAttribute("data-controller")) {
                elem.removeAttribute("data-controller");
            } else {
                elem.setAttribute("data-controller", "article--dragNDrop");
            }
        });
    }

    closeEdit() {
        let buttons = this.element.getElementsByClassName("cancel-edit");
        for (let i = 0; i < buttons.length; ++i) {
            Promise.resolve(buttons[i].click());
        }
    }

}
