import { Controller } from "@hotwired/stimulus"
import NoSleep from "nosleep.js"

export default class extends Controller {
    connect() {
        this.noSleep = new NoSleep();
        this.noSleep.enable();
    }

    disconnect() {
        this.noSleep.disable();
    }
}