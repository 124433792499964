import {I18n} from "i18n-js";

//extract locale name from translation file
function getLocale(translationJsonString){
    // to avoid the beginning {" of file
    const startGap = 2;
    const endIndex = translationJsonString.substring(startGap).indexOf('"') + startGap;
    return translationJsonString.substring(startGap, endIndex);
}

function loadTranslations(i18n, translationFiles) {
    for (const [key, value] of Object.entries(translationFiles)) {
        const translationJsonString = JSON.stringify(value.default);
        const locale = getLocale(translationJsonString);
        console.log("Loading translation for: " + locale);
        const translationsJson = JSON.parse(translationJsonString)
        i18n.store(translationsJson);
    }
}

export default class I18nLoader {
    static loadI18n() {
        //load the correct locale
        const htmlLang = document.documentElement.lang;

        // load only the translation file for the current locale
        //let translationFiles = import('@/locales/' + htmlLang + '.json', { eager: true });
        //if (Object.keys(translationFiles).length === 0) {
        //load all translation files if the current locale is not found
        const translationFiles = import.meta.glob('@/locales/*.json', { eager: true });

        //}

        //instanciate the i18n object
        const i18n = new I18n();
        i18n.locale = htmlLang;
        //load translations from files
        loadTranslations(i18n, translationFiles);


        return i18n;
    }

}