import { Controller } from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";

export default class extends Controller {
    static targets = ["element", "loadOnConnect"]

    connect() {

        this.showOnConnect();
    }

    showOnConnect() {
        if (this.hasLoadOnConnectTarget) {
            const element = this.loadOnConnectTarget;
            // the show method come from the shoelace library
            element.show();
        }
    }

    open() {
        const element = this.elementTarget
        // the show method come from the shoelace library
        element.show();
    }
    close() {
        const element = this.elementTarget
        // the hide method come from the shoelace library
        element.hide();
    }

}
