import { Controller } from "@hotwired/stimulus"
import DriverFactory from "@/js/lib/DriverFactory.js";

export default class extends Controller {
    static targets = [ "element" ]

    autoTriggerMap = new Map();

    connect() {
        //instanciate the driver
        this.driver = DriverFactory.defaultDriver(this.application.i18n);
        this.setStrategy(this.findStrategyName());

        // call the autoTrigger method to auto call help on specific events
        // for turbo streams
        if (document.readyState === 'complete') {
            this.autoTrigger();
        }
        // for the first page load
        else {
            document.addEventListener('DOMContentLoaded', () => {
                this.autoTrigger();
            });
        }


    }

    show(){
        //update the config with the new strategy
        this.updateSate();
        //execute the associate method of the strategy to get the driver config
        const conf = this[this.strategyName]();
        //set the config to the driver
        this.driver.setConfig(conf);

        this.driver.drive();
    }

    updateSate(){
        //update state
        const domStrategy = this.findStrategyName();
        if(domStrategy !== this.strategyName){
            this.setStrategy(domStrategy);
        }

    }

    setStrategy(strategyName){
        this.strategyName = strategyName;
    }

    findStrategyName(){
        return this.element.dataset.strategy;
    }

     autoTrigger(){
        let controller = this;
        this.autoTriggerMap.forEach(function (strategy, condition) {
            if (condition === "true" || condition === true) {
                if(!localStorage.getItem(strategy)) {
                    localStorage.setItem(strategy,"1");

                    const conf = controller[strategy]();
                    //set the config to the driver
                    controller.driver.setConfig(conf);

                    controller.driver.drive();
                }
            }
        });
    }
}