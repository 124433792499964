import { Controller } from "@hotwired/stimulus"
import {FetchRequest} from "@rails/request.js";

export default class extends Controller {
    static targets = [ "element", "reverseElement", "img", "input", "elementCheckBox"]

    style_display  = this.element.dataset.display;

    connect() {
        super.connect();
        this.style_display = this.element.dataset.display;
    }

    elementTargetConnected(target) {
        target.style.display = this.style_display;
    }

    display() {
        this.style_display = this.style_display === "none" ? "block" : "none";
        let elems = this.elementTargets;
        elems.forEach((elem) => {
            elem.style.display = this.style_display;
        });
    };

    reverseDisplay() {
        let elems = this.reverseElementTargets;
        const styleToDisplay = this.style_display === "none" ? "block" : "none";
        elems.forEach((elem) => {
            elem.style.display = styleToDisplay;
        });
    }

    imagePreview() {
        var input = this.inputTarget
        var output = this.imgTarget

        if (input.files && input.files[0]) {
            if (input.files[0].type.substring(0, 6) === "image/") {
                var reader = new FileReader();

                reader.onload = function () {
                    output.src = reader.result
                }

                reader.readAsDataURL(input.files[0]);
            } else {
                output.src = ""
            }
        } else {
            output.src = ""
        }
    }

    check() {
        const dataset = this.elementCheckBoxTarget.dataset;
        const keys = Object.keys(dataset);
        let url = "";
        keys.filter(key => key.startsWith("check_")).forEach(key => {
            const newKey = key.replace(/^check_/, "");
            url += "/"+newKey+"/"+dataset[key];
        });
        const request = new FetchRequest('get', url);
        request.perform().then((response) => {
            console.log("success");
        });
    }

}
