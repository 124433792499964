import DriverFactory from "@/js/lib/DriverFactory.js";
import Help_controller from "@/js/controllers/shared/help_controller";

function participantGetFirstReadySession(sessions){
    for (let i = 0; i < sessions.length; i++) {
        if (sessions[i].getElementsByClassName("order-button").length > 0){
            return sessions[i];
        }
    }
}

export default class extends Help_controller {
    static targets = ["element"]

    notReadyStrategies = ["owner_no_name", "owner_no_menu", "owner_no_participants"];
    readyStrategies = ["owner_wait_for_orders", "owner_need_to_lock_orders", "owner_locked_unfinished_order", "owner_cooking_in_progress"]


    connect() {
        // setting the auto trigger map for help on specific events

        if (this.element.dataset['strategy'] === 'owner_empty_event') {
            this.autoTriggerMap.set(true, 'owner_empty_event');
        }

        if (this.element.dataset['strategy'] === 'owner_no_invites'){
            /*var firstNotReadySession = undefined;
            let sessions = document.getElementById("evsessions").children;
            for (let i = 0; i < sessions.length; i++) {
                if (this.notReadyStrategies.includes(sessions[i].getAttribute("data-strategy")) ) {
                    firstNotReadySession = sessions[i];
                    break;
                }
            }
            if (firstNotReadySession) {
                this.autoTriggerMap.set(true, 'owner_no_invites');
            }*/
        }

        // always set the autoTriggerMap before calling super.connect()
        super.connect();
    }


    // Explanation of the event, the organisation of events into sessions and what this brings to the user.
    // With an organizer-oriented point of view.
    owner_empty_event(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.event.show.owner.emptyEvent';
        const sharedScope = 'frontend.help.event.show.shared';
        let conf = DriverFactory.defaultConfig(i18n);

        const turboFrameSession = document.getElementById("evsessions").children[0];

        // cancel edit if user is editing
        const cancelButon = turboFrameSession.getElementsByClassName("cancel-button")[0];
        if (cancelButon) {
            cancelButon.click();
        }

        conf.steps = [
            { element: "main" , popover: { title: i18n.t("orgaEventSynthesis.title", {scope: sharedScope}), description: i18n.t("orgaEventSynthesis.content", {scope: sharedScope})} },
            { element: turboFrameSession , popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope})} },
            { element: turboFrameSession.getElementsByClassName("edit-button")[0] , popover: { title: i18n.t("steps.1.title", {scope: scope}), description: i18n.t("steps.1.content", {scope: scope}),
                    onNextClick: () => {
                        turboFrameSession.getElementsByClassName("edit-button")[0].click();
                        this.driver.moveNext();
                    }} },
            { element: turboFrameSession, popover: { title: i18n.t("steps.2.title", {scope: scope}), description: i18n.t("steps.2.content", {scope: scope}),
                    onPrevClick: async () => {
                        turboFrameSession.getElementsByClassName("cancel-button")[0].click();
                        await Promise.allSettled([
                            customElements.whenDefined("sl-button-group"),
                        ]);
                        this.driver.movePrevious();
                    } }},
            { element: turboFrameSession.getElementsByClassName("submit-button")[0], popover: { title: i18n.t("steps.3.title", {scope: scope}), description: i18n.t("steps.3.content", {scope: scope})} },
        ]
        return conf;
    }

    owner_no_invites(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.event.show.owner.noInvites';
        let conf = DriverFactory.defaultConfig(i18n);

        const inviteButton = document.getElementsByClassName("invite-button")[0];

        conf.doneBtnText = i18n.t('doneBtnText', {scope: scope});
        conf.steps = [
            { element: "main" , popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope})} },
            { element: inviteButton , popover: { title: i18n.t("steps.1.title", {scope: scope}), description: i18n.t("steps.1.content", {scope: scope}),
                    onNextClick: () => {
                        inviteButton.click();
                        this.driver.moveNext();
                    }} },
        ]
        return conf;
    }

    owner_no_session(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.event.show.owner.noSession';
        const sharedScope = 'frontend.help.event.show.shared';
        let conf = DriverFactory.defaultConfig(i18n);

        const menuBarForm = document.getElementsByClassName("new_session")[0];
        const form = document.getElementById("new_evsession");

        conf.steps = [
            { element: "main" , popover: { title: i18n.t("orgaEventSynthesis.title", {scope: sharedScope}), description: i18n.t("orgaEventSynthesis.content", {scope: sharedScope})} },
            { element: menuBarForm , popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope}),
                nextBtnText: i18n.t('steps.0.nextBtnText', {scope: scope}),
                onNextClick: () => {
                    menuBarForm.children[1].click();
                    this.driver.moveNext();
                }} },
            { element: form , popover: { title: i18n.t("steps.1.title", {scope: scope}), description: i18n.t("steps.1.content", {scope: scope}),
                    nextBtnText: i18n.t('steps.1.nextBtnText', {scope: scope}) }},
        ]
        return conf;
    }

    owner_pause(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.event.show.owner.pause';
        let conf = DriverFactory.defaultConfig(i18n);

        const pauseButton = document.getElementsByClassName("pause")[0];

        conf.doneBtnText = i18n.t('doneBtnText', {scope: scope});
        conf.steps = [
            { element: "main" , popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope})} },
            { element: pauseButton , popover: { title: i18n.t("steps.1.title", {scope: scope}), description: i18n.t("steps.1.content", {scope: scope}),
                    onNextClick: () => {
                        pauseButton.getElementsByTagName("a")[0].click();
                        this.driver.moveNext();
                    }} },
        ];

        return conf;
    }

    owner_no_ready_session(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.event.show.owner.noReadySession';
        let conf = DriverFactory.defaultConfig(i18n);

        var firstNotReadySession = undefined;
        let sessions = document.getElementById("evsessions").children;
        for (let i = 0; i < sessions.length; i++) {
            if (this.notReadyStrategies.includes(sessions[i].getAttribute("data-strategy")) ) {
                firstNotReadySession = sessions[i];
                break;
            }
        }
        conf.doneBtnText = i18n.t('doneBtnText', {scope: scope});
        conf.steps = [
            { element: "main" , popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope}),
                onNextClick: () => {
                    firstNotReadySession.getElementsByClassName("evsession-index-help")[0].click();
                }} },
        ];
        return conf;
    }

    owner_ready(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.event.show.owner.ready';
        let conf = DriverFactory.defaultConfig(i18n);

        var firstReadySession = undefined;
        let sessions = document.getElementById("evsessions").children;
        for (let i = 0; i < sessions.length; i++) {
            if (this.readyStrategies.includes(sessions[i].getAttribute("data-strategy")) ) {
                firstReadySession = sessions[i];
                break;
            }
        }
        conf.doneBtnText = i18n.t('doneBtnText', {scope: scope});
        conf.steps = [
            { element: "main" , popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope}),
                    onNextClick: () => {
                        firstReadySession.getElementsByClassName("evsession-index-help")[0].click();
                    }} },
        ];
        return conf;
    }

    participant_empty_event(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.event.show.participant.emptyEvent';
        let conf = DriverFactory.defaultConfig(i18n);

        conf.steps = [
            { element: "main" , popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope}),} },
        ]

        return conf;
    }

    participant_not_participating(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.event.show.participant.notParticipating';
        let conf = DriverFactory.defaultConfig(i18n);

        const eventName = document.querySelector(".event_list").getElementsByTagName("H2")[0].textContent;
        const firstEvsessionJoin = document.getElementsByClassName("evsession")[0].getElementsByClassName("participation")[0];

        conf.steps = [
            { element: "main" , popover: { title: i18n.t("steps.0.title", {scope: scope, eventName: eventName}), description: i18n.t("steps.0.content", {scope: scope, eventName: eventName}),} },
            { element: firstEvsessionJoin , popover: { title: i18n.t("steps.1.title", {scope: scope}), description: i18n.t("steps.1.content", {scope: scope}),
                nextBtnText: i18n.t('steps.1.nextBtnText', {scope: scope}),
                onNextClick: () => {
                    firstEvsessionJoin.click();
            } }},
        ]

        return conf;
    }

    participant_participating(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.event.show.participant.participating';
        const sharedScope = 'frontend.help.event.show.shared';
        let conf = DriverFactory.defaultConfig(i18n);


        const sessions = document.getElementById("evsessions").children;

        let evsession = participantGetFirstReadySession(sessions);
        if (evsession === undefined) return conf;

        const evsessionName = evsession.getElementsByClassName("title")[0].textContent;
        const orderButton = evsession.getElementsByClassName("order-button")[0];

        conf.steps = [
            { element: evsession , popover: { title: i18n.t("beforeOrder.title", {scope: sharedScope}), description: i18n.t("beforeOrder.content", {scope: sharedScope, barbecueName: evsessionName}),} },
            { element: orderButton , popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope}),
                    nextBtnText: i18n.t('steps.0.nextBtnText', {scope: scope}),
                    onNextClick: () => {
                        orderButton.click();
                    } }},
        ]

        return conf;
    }

    participant_participating_but_no_menu(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.event.show.Participant.participatingButNoMenu';
        const sharedScope = 'frontend.help.event.show.shared';
        let conf = DriverFactory.defaultConfig(i18n);


        const sessions = document.getElementById("evsessions").children;

        let evsession = participantGetFirstReadySession(sessions);
        if (evsession === undefined) return conf;

        const evsessionName = evsession.getElementsByClassName("title")[0].textContent;

        conf.steps = [
            { element: evsession , popover: { title: i18n.t("beforeOrder.title", {scope: sharedScope}), description: i18n.t("beforeOrder.content", {scope: sharedScope, barbecueName: evsessionName}),} },
            { element: evsession , popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope}),}},
        ]

        return conf;
    }

    participant_pause(){
        const i18n = this.application.i18n;
        const scope = 'frontend.help.event.show.participant.pause';
        let conf = DriverFactory.defaultConfig(i18n);

        const homeButton = document.getElementsByTagName("H1")[0].getElementsByTagName("A")[0];

        conf.doneBtnText = i18n.t('doneBtnText', {scope: scope});
        conf.steps = [
            { element: "main" , popover: { title: i18n.t("steps.0.title", {scope: scope}), description: i18n.t("steps.0.content", {scope: scope}),
                    onNextClick: () => {
                        homeButton.click();
                    }} },
        ];

        return conf;
    }

}